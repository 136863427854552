import { ColorPalette } from '@komo-tech/core/models/ColorPalette';
import {
  Accordion as MantineAccordion,
  Button as MantineButton,
  ColorInput as MantineColorInput,
  createTheme as createMantineTheme,
  DEFAULT_THEME as MANTINE_DEFAULT_THEME,
  DefaultMantineColor,
  Divider as MantineDivider,
  getFontSize as getMantineFontSize,
  Group as MantineGroup,
  Input as MantineInput,
  MantineColorsTuple,
  MantineTheme,
  MantineThemeOverride,
  mergeMantineTheme,
  Modal as MantineModal,
  ModalOverlay as MantineModalOverlay,
  ModalRoot as MantineModalRoot,
  MultiSelect as MantineMultiSelect,
  PinInput as MantinePinInput,
  Popover as MantinePopover,
  ScrollAreaAutosize as MantineScrollAreaAutosize,
  Select as MantineSelect,
  Switch as MantineSwitch,
  Tooltip as MantineTooltip,
  TransitionOverride as MantineTransitionOverride
} from '@mantine/core';
import { Spotlight as MantineSpotlight } from '@mantine/spotlight';

import { ExtendedColorPalettes, ExtendedCustomColors } from './ExtendedColors';
import {
  resolveSiteThemeValues,
  SiteThemeProps
} from './resolveSiteThemeValues';
import { ISiteThemeProperties } from './types';

declare module '@mantine/core' {
  type Other = IOtherThemeOverride['other'];
  export interface MantineThemeOther extends Other {}
  export interface MantineThemeColorsOverride {
    colors: Record<
      ExtendedCustomColors | DefaultMantineColor,
      MantineColorsTuple
    >;
  }
}

type IOtherThemeOverrideValue = {
  colors: {
    black: string;
    white: string;
    previewHighlight: string;
    komoPurple: string;
    palettes: ColorPalette[];
    menuHighlight: string;
  };
  cardContainerSize: number;
  site: SiteThemeProps;
};

export interface IOtherThemeOverride {
  other: IOtherThemeOverrideValue;
}

export const resolveOtherThemeOverride = (options?: {
  palettes?: ColorPalette[];
  properties?: ISiteThemeProperties;
}): IOtherThemeOverrideValue => ({
  cardContainerSize: 672,
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    previewHighlight: '#0BB6FF',
    komoPurple: '#5A05C5',
    menuHighlight: '#f2f7fb',
    palettes: options?.palettes || []
  },
  site: resolveSiteThemeValues(options?.properties)
});

export type MantineThemeProviderValue = Omit<MantineThemeOverride, 'other'> & {
  other: IOtherThemeOverrideValue;
};

const defaultTransitionProps: MantineTransitionOverride = {
  transition: 'pop'
};

export const resolveThemeForProvider = (options?: {
  properties?: ISiteThemeProperties;
  fontFamily?: string;
  palettes?: ColorPalette[];
  primaryColor?: MantineThemeProviderValue['primaryColor'];
}): MantineThemeOverride => {
  const palettes =
    options?.palettes || options?.properties?.getColorPalettes?.() || [];

  const primaryColor = options?.primaryColor || 'komo-primary';

  return createMantineTheme({
    fontFamily: options?.fontFamily ?? MANTINE_DEFAULT_THEME.fontFamily,
    primaryColor,
    primaryShade: 7,
    cursorType: 'pointer',
    luminanceThreshold: 0.4,
    components: {
      Button: MantineButton.extend({
        defaultProps: {
          size: 'sm',
          variant: 'filled'
        }
      }),
      Accordion: MantineAccordion.extend({
        defaultProps: {}
      }),
      ActionButton: {
        defaultProps: {
          variant: 'subtle',
          size: 'md'
        }
      },
      ColorInput: MantineColorInput.extend({
        defaultProps: {
          format: 'hexa',
          withPicker: true,
          withEyeDropper: true,
          swatchesPerRow: 10,
          popoverProps: {
            transitionProps: defaultTransitionProps,
            withinPortal: true
          }
        }
      }),
      Divider: MantineDivider.extend({
        defaultProps: {
          labelPosition: 'center'
        }
      }),
      Group: MantineGroup.extend({
        defaultProps: {
          wrap: 'nowrap',
          preventGrowOverflow: false
        }
      }),
      InputWrapper: MantineInput.Wrapper.extend({
        styles: (_, { size }) => ({
          label: {
            fontSize: `calc(${getMantineFontSize(size)} * 0.9)`,
            cursor: 'inherit'
          },
          description: {
            fontSize: `calc(${getMantineFontSize(size)} * 0.85)`,
            cursor: 'default'
          }
        })
      }),
      Modal: MantineModal.extend({
        defaultProps: {
          opened: true,
          withinPortal: false,
          centered: true,
          scrollAreaComponent: MantineScrollAreaAutosize,
          transitionProps: defaultTransitionProps
        }
      }),
      ModalRoot: MantineModalRoot.extend({
        defaultProps: {
          withinPortal: false,
          centered: true,
          scrollAreaComponent: MantineScrollAreaAutosize,
          transitionProps: defaultTransitionProps
        }
      }),
      ModalOverlay: MantineModalOverlay.extend({
        defaultProps: {}
      }),
      Spotlight: MantineSpotlight.extend({
        defaultProps: { scrollAreaComponent: null, centered: false }
      }),
      MultiSelect: MantineMultiSelect.extend({
        defaultProps: {
          comboboxProps: {
            transitionProps: defaultTransitionProps
          }
        }
      }),
      PinInput: MantinePinInput.extend({
        vars: (_, { size }) => {
          if ((size as any) === 'xxl') {
            return {
              root: {
                '--pin-input-size': '4rem'
              },
              pinInput: {
                '--input-fz': 'var(--mantine-font-size-xl)',
                '--input-height': 'var(--input-height-xl)'
              }
            };
          }
          return { root: {} };
        },
        defaultProps: {
          w: '100%',
          h: '100%'
        }
      }),
      Popover: MantinePopover.extend({
        defaultProps: {
          transitionProps: defaultTransitionProps,
          shadow: 'sm'
        }
      }),
      Select: MantineSelect.extend({
        defaultProps: {
          comboboxProps: {
            transitionProps: defaultTransitionProps
          }
        }
      }),
      Switch: MantineSwitch.extend({
        styles: {
          body: {
            alignItems: 'center'
          }
        }
      }),
      Tooltip: MantineTooltip.extend({
        defaultProps: {
          withinPortal: true,
          transitionProps: defaultTransitionProps,
          multiline: true,
          maw: 240,
          w: 'max-content',
          ta: 'left'
        },
        styles: {
          tooltip: {
            fontSize: '0.75rem',
            fontWeight: 450
          }
        }
      })
    },
    other: {
      ...resolveOtherThemeOverride({
        properties: options?.properties,
        palettes: palettes
      })
    },
    colors: ExtendedColorPalettes
  });
};

export const DefaultTheme = mergeMantineTheme(
  MANTINE_DEFAULT_THEME,
  resolveThemeForProvider()
) as MantineTheme;
