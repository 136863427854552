import 'intersection-observer';
// Global styles
import '@xyflow/react/dist/style.css';
import '@komo-tech/ui/styles.css';
import './_app.css';

import { ErrorBoundary } from '@komo-tech/ui/ErrorBoundary';
import { initModal } from '@komo-tech/ui/Modal/init';
import { ThemeProvider } from '@komo-tech/ui/theme/Provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FCC } from 'fcc';
import App from 'next/app';
import React from 'react';

import { NextPageWithLayout } from '@/common/models/NextPageWithLayout';
import { isProduction, isServer } from '@/common/utils/NextFunctions';
import { SiteErrored } from '@/front/components/site/SiteHost/SiteErrored';

import { init } from '../init';

init();
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => console.error(error)
    },
    queries: {
      onError: (error) => console.error(error)
    }
  }
});

export const AppErrorBoundary: FCC = ({ children }) => (
  <ErrorBoundary
    fallback={
      <ThemeProvider>
        <SiteErrored />
      </ThemeProvider>
    }
  >
    {children}
  </ErrorBoundary>
);

const redirectIfNotSecure = () => {
  if (!isProduction()) {
    return;
  }
  if (
    !isServer() &&
    window.location.hostname !== 'localhost' &&
    window.location.protocol !== 'https:'
  ) {
    location.replace(
      `https:${location.href.substring(location.protocol.length)}`
    );
  }
};

class KomoApp extends App {
  UNSAFE_componentWillMount() {
    redirectIfNotSecure();
  }

  componentDidMount() {
    initModal(document, {
      resolveRootElement: (d) => d.getElementById('__next')
    });
  }

  render() {
    const { Component, pageProps } = this.props;
    const getLayout =
      (Component as NextPageWithLayout).getLayout || ((page) => page);

    return (
      <QueryClientProvider client={queryClient}>
        <AppErrorBoundary>
          {getLayout(<Component {...pageProps} />)}
        </AppErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  }
}

export default KomoApp;
